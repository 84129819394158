<template>
  <div id="Privacy">
    <div :class="privacyPage.layout" v-html="privacy"></div>
  </div>
</template>

<script setup>
// Imports

import { onMounted, ref } from 'vue'
import { scroll } from 'quasar'
import { marked } from 'marked'
import axios from 'axios'

// Props

// Emits

// Variables

// const store = useStore()
const { getScrollTarget, setVerticalScrollPosition } = scroll

// Responses

const privacy = ref(null)

// Methods

const anchorToTop = () => {
  const target = document.getElementById('Privacy')
  const getScroll = getScrollTarget(target)
  const offset = target.offsetTop
  const duration = 0
  setVerticalScrollPosition(getScroll, offset, duration)
}

// Lifecycles

onMounted(async () => {
  privacy.value = await axios.get(`${process.env.VUE_APP_PRIVACY}`)
    .then((res) => {
      return marked.parse(res.data)
    })
  anchorToTop()
})
</script>

<style module="privacyPage">

.layout {
  width: 100%;
  padding: 32px 14px;
}

.layout h1 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
}

.layout h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
}

.layout h3 {
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.4rem;
}

.layout h4 {
  font-size: 1.6rem;
  font-weight: bold;
}

.layout div {
  padding: 0;
  margin: 0;
}

.layout p {
  font-size: 0.8rem;
}

.layout ol {
  font-size: 0.2rem;
  padding: 12px 0 12px 24px;
}

.layout li {
  font-size: 0.7rem;
  margin: 0;

}

.layout hr {
  width: 100%;
  background-color: #fff;
  border: 0;
  max-width: 0;
}

.layout table {
  margin: 1rem 0 1.6rem;
}

.layout th {
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0 2px;
  background-color: #eeeeee;
}

.layout td {
  font-size: 0.6rem;
  margin: 0;
  padding: 0 4px;
}

.layout strong {
  font-size: 0.8rem;
}

.layout a {
  font-size: 0.8rem;
  color: #0070f3;
  text-decoration: none;
}

</style>
